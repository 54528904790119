"use strict";


//
// our own code for obfuscation of electronic means to reach me
//

window.dynelch = "-@_.tmliao";

window.addEventListener('load', async function() {
    const items = document.querySelectorAll(".dyn-electr-addr");
    for (const el of items) {
        const stem = el.dataset.stem;
        const domn = el.dataset.domn;
        let a = document.createElement('a');
        a.classList.add('dyn-electr-addr-set');
        a.innerHTML =
            stem.substr(0,1)
            + '<span class="dyn-electr-addr-blsq">\u25A0\u25A0\u25A0</span>'
            + '<span class="dyn-electr-addr-aasymb"></span>'
            + '<span class="dyn-electr-addr-blsq">\u25A0\u25A0\u25A0</span>'
        ;
        a.dataset.domn = domn;
        a.dataset.stem = stem;

        var fn = function(event) {
            event.preventDefault();
            const aa = this;
            const newaa = document.createElement('a');
            newaa.classList.add('dyn-electr-addr-full');
            newaa.innerHTML =
                aa.dataset.stem
                + window.dynelch.substring(1,2)
                + aa.dataset.domn;
            newaa.setAttribute(
                'href',
                window.dynelch.substring(5,6) + window.dynelch.substring(8,9)
                    + window.dynelch.substring(7,8) + window.dynelch.substring(6,7)
                    + window.dynelch.substring(4,5) + window.dynelch.substring(9,10) + ':'
                    + aa.dataset.stem + window.dynelch.substring(1,2) + aa.dataset.domn
            );
            aa.replaceWith(newaa);
            return false;
        };

        a.addEventListener('mouseover', fn);
        a.addEventListener('click', fn);
        el.replaceWith(a);
    };
});
